import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Layout from "../components/Layout";
import Head from "next/head";
import Link from "next/link";
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react'
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'


export default function Login({ }) {
    const session = useSession()
    const supabase = useSupabaseClient()
    const router = useRouter();

    useEffect(() => {
        // If the user is logged in, redirect to the homepage
        if (session) {
            router.push('/planner');
        }
    }, [session, router]);

    return (
        <Layout JournalEntries home="true">
            <div className="" style={{ padding: '50px 0 100px 0' }}>
                {!session && (
                    <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
                )}
            </div>
        </Layout>
    )
}